import React from 'react';
import styled from 'styled-components';

const ModalHeaderContainer = styled.div`
  padding: 8px 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ModalHeader = ({ children }) => {
  return <ModalHeaderContainer>{children}</ModalHeaderContainer>;
};

export default ModalHeader;
