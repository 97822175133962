import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/layout';
import Modal, { ModalBody } from 'components/Modal';
import AppointmentsCancelFailedPage from 'components/appointments/cancel/FailedPage';
import { t } from 'src/i18n/config';

const Failed = ({ location }) => {
  const { state = {} } = location;
  return (
    <Layout>
      <Helmet>
        <title>{t('myAppointments')}</title>
      </Helmet>
      <Modal>
        <ModalBody>
          <AppointmentsCancelFailedPage
            message={state == null ? undefined : state.message}
            contact={state == null ? undefined : state.contact}
          ></AppointmentsCancelFailedPage>
        </ModalBody>
      </Modal>
    </Layout>
  );
};

export default Failed;
