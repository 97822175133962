import React from 'react';
import styled from 'styled-components';

const ModalFooterContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 16px 20px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
  background-color: white;
`;

const ModalFooter = ({ children }) => {
  return <ModalFooterContainer>{children}</ModalFooterContainer>;
};

export default ModalFooter;
